<template>
  <div class="single_number_chart">
    <v-sheet class="number" :color="color" elevation="0">
      <div class="text-center overline title" :style="{ color: textColor }">
        {{ title }}
      </div>
      <div class="fill-height d-flex align-center justify-center">
        <span class="font-weight-bold" :style="{ color: textColor }">
          {{ value }}
        </span>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'SingleNumberChart',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'primary',
    },
    textColor: {
      type: String,
      default: '#fff',
    },
  },
}
</script>

<style lang="scss">
.single_number_chart {
  .number {
    font-size: 5rem;
    border: 3px solid white !important;

    .title {
      position: relative;
      top: 15px;
      font-size: 1rem !important;
    }
  }
}
</style>
