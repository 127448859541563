<template>
  <v-card-title>
    <v-row>
      <v-col>Agenti Virtuali</v-col>
      <v-col>
        <div class="d-flex justify-end">
          <!-- <span>Cliente</span> -->
        </div>
      </v-col>
    </v-row>
  </v-card-title>
</template>

<script>
export default {
  name: 'Titlebar',
}
</script>
