<template>
  <v-card elevation="0" flat outlined tile>
    <v-card-title>
      <v-row>
        <v-col class="d-flex justify-center">Motivi di Contatto</v-col>
      </v-row>
    </v-card-title>
    <pie-chart
      :messages="{ empty: 'Non ci sono dati' }"
      :data="data"
      :library="library"
    ></pie-chart>
  </v-card>
</template>

<script>
export default {
  name: 'SessionsPerReason',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      library: {
        layout: {
          padding: {
            left: 16,
            right: 16,
            top: 0,
            bottom: 16,
          },
        },
        legend: { display: true, position: 'bottom' },
      },
    }
  },
}
</script>
