<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card elevation="0">
          <TitleBar />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SingleNumberChart
          title="Totale sessioni"
          :value="charts.in_numbers.data.total"
        />
      </v-col>
      <v-col>
        <SingleNumberChart
          title="Sessioni Gestite"
          :value="charts.in_numbers.data.positive"
          color="success"
        />
      </v-col>
      <v-col>
        <SingleNumberChart
          title="Ricontatti"
          :value="charts.in_numbers.data.rework"
          color="#b0cfb7"
        />
      </v-col>
      <v-col>
        <SingleNumberChart
          title="In Corso"
          :value="charts.in_numbers.data.pending"
          color="#6e7b7d"
        />
      </v-col>
      <v-col>
        <SingleNumberChart
          title=" Scadute"
          :value="charts.in_numbers.data.expired"
          color="#35495e"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <SessionsPerDay :data="charts.sessions_per_day.data" />
      </v-col>
      <v-col cols="3">
        <SessionsPerReason :data="charts.sessions_per_reason.data" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SessionsDuringDay :data="charts.sessions_during_day.data" />
      </v-col>
    </v-row>
    <v-btn
      fab
      fixed
      bottom
      right
      x-large
      color="success"
      :to="{ name: 'virtualagents_create_quick' }"
    >
      <v-icon>mdi-robot-excited-outline</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import TitleBar from '@components/virtualagents/dashboard/Titlebar.vue'
import SingleNumberChart from '@components/virtualagents/dashboard/charts/SingleNumberChart.vue'
import SessionsDuringDay from '@components/virtualagents/dashboard/charts/SessionsDuringDay.vue'
import SessionsPerDay from '@components/virtualagents/dashboard/charts/SessionsPerDay.vue'
import dashboard from '@mixins/dashboard'
import SessionsPerReason from '@components/virtualagents/dashboard/charts/SessionsPerReason.vue'

export default {
  name: 'VirtualAgentsDashboard',
  components: {
    SessionsPerReason,
    TitleBar,
    SingleNumberChart,
    SessionsDuringDay,
    SessionsPerDay,
  },
  mixins: [dashboard],
  data() {
    return {
      resource: 'virtualAgentsSessions',
      charts: {
        in_numbers: {
          data: {
            total: 0,
            positive: 0,
            rework: 0,
            pending: 0,
            expired: 0,
          },
          loading: false,
          scope: 'va_sessions_grouped_by_status',
        },
        sessions_during_day: {
          data: [],
          loading: false,
          scope: 'va_sessions_during_day',
        },
        sessions_per_day: {
          data: [],
          loading: false,
          scope: 'va_sessions_per_day',
        },
        sessions_per_reason: {
          data: [],
          loading: false,
          scope: 'va_sessions_per_reason',
        },
      },
    }
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    async getChartData() {
      const charts = Object.entries(this.charts)
      charts.forEach(([name, chart]) => {
        this.charts[name].loading = true
      })
      await Promise.all(
        charts.map(([name, chart]) =>
          this.getReportData({
            resource: this.resource,
            scope: chart.scope,
          })
        )
      )
      charts.forEach(([name, chart]) => {
        this.charts[name].data = this.dashboardData(this.resource, chart.scope)
        this.charts[name].loading = false
      })
    },
  },
}
</script>
